import React, { memo } from 'react';
import Layout from "../components/layout/layout";

const days = [
  {
    "name": "Maandag",
    "frenchName": "Lundi",
    "englishName": "Monday",
    "closed": true
  },
  {
    "name": "Dinsdag",
    "frenchName": "Mardi",
    "englishName": "Tuesday",
    "soup": "Champignonsoep",
    "frenchSoup": "Soupe de champignons",
    "englishSoup": "Mushroom soup",
    "mainCourse": "Pasta met scampi, knoflook en courgette",
    "frenchMainCourse": "Pâtes aux scampis, à l'ail et à la courgette",
    "englishMainCourse": "Pasta with scampi, garlic, and zucchini",
    "dessert": null
  },
  {
    "name": "Woensdag",
    "frenchName": "Mercredi",
    "englishName": "Wednesday",
    "soup": "Pastinaaksoep",
    "frenchSoup": "Soupe de panais",
    "englishSoup": "Parsnip soup",
    "mainCourse": "Varkensspiering met mosterdsaus, frietjes en salade",
    "frenchMainCourse": "Spiringue de porc, sauce moutarde, frites et salade",
    "englishMainCourse": "Pork spiring with mustard sauce, fries and salad",
    "dessert": null
  },
  {
    "name": "Donderdag",
    "frenchName": "Jeudi",
    "englishName": "Thursday",
    "soup": "Wortelsoep",
    "frenchSoup": "Soupe carotte",
    "englishSoup": "Carrot soup",
    "mainCourse": "Boerenworst, preipuree en kalfsjus",
    "frenchMainCourse": "Saucisse de campagne, purée de poireaux et jus de veau",
    "englishMainCourse": "Farmer`s sausage, leek mashed potatoes and meat gravy",
    "dessert": null
  },
  {
    "name": "Vrijdag",
    "frenchName": "Vendredi",
    "englishName": "Friday",
    "soup": "Bloemkoolsoep",
    "frenchSoup": "Soupe de Chou-fleur",
    "englishSoup": "Cauliflower soup",
    "mainCourse": "Zalmfilet met bernaisesaus en frietjes",
    "frenchMainCourse": "Filet de saumon, sauce béarnaise et frites",
    "englishMainCourse": "Salmon fillet, béarnaise sauce and fries",
    "dessert": null
  },
  {
    "name": "Zaterdag",
    "frenchName": "Samedi",
    "englishName": "Saturday",
    "soup": "Knolseldersoep",
    "frenchSoup": "Soupe de cèleri-rave",
    "englishSoup": "Celeriac Soup",
    "mainCourse": "Kippenpoot zonder botten met pepersaus en kroketjes",
    "frenchMainCourse": "Cuisse de poulet désossée, sauce au poivre et croquettes de pommes de terre",
    "englishMainCourse": "Chicken leg with pepper sauce and potato croquettes",
    "dessert": null
  },
  {
    "name": "Zondag",
    "frenchName": "DimaNche",
    "englishName": "Sunday",
    "soup": "Groenteroomsoep",
    "frenchSoup": "Creme de légumes",
    "englishSoup": "Vegetable cream soup",
    "mainCourse": "Osso buco met tagliatelle en parmezaan",
    "frenchMainCourse": "Osso buco accompagné de tagliatelles et de parmesan",
    "englishMainCourse": "Osso buco with tagliatelle and parmesan",
    "dessert": null
  }
];

const Lunch = () => {
  return (
      <>
          <Layout/>
          <main className="main">
              <section className='lunch'/>
              <div className="best_price">
                  <img
                      loading='lazy'
                      src="/images/best_price_2.webp"
                      alt="Lunch"
                  />
              </div>
              <div className="top_title">
                  <h1>Lunch</h1>
              </div>

              <div className='lunch_wrap'>
                  <div className='lunch_box'>
                      <h3>31.03.2025 - 06.04.2025</h3>
                      <h3>11:30 – 15:00</h3>
                      {days.map((day, index) => (
                          <div key={index}>
                              <h4>{day.name} – {day.frenchName} – {day.englishName}</h4>
                              {day.closed ? (
                                  <p>Gesloten</p>
                              ) : (
                                  <>
                                      <p>{day.soup} - {day.frenchSoup} - {day.englishSoup}</p>
                                      <p>{day.mainCourse}</p>
                                      <p>{day.frenchMainCourse}</p>
                                      <p>{day.englishMainCourse}</p>
                                      {day.dessert && (
                                          <p>{day.dessert} - {day.frenchDessert} - {day.englishDessert}</p>
                                      )}
                                  </>
                              )}
                          </div>
                      ))}
                  </div>
              </div>
          </main>
      </>
  );
};

export default memo(Lunch);
